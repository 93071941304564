<template>
  <div class="cell">
    <div class=" f_flex" @click.stop="goPostsInfo">
      <div class="avatar f_s0">
        <group-avatar @change='goPostsInfo' :avatarList='[info.groupAvatar]'></group-avatar>
      </div>
      <div class="f_g1 f_s0">
        <div class="title" :style="titleStyle">{{info.groupName}}</div>
        <div style="font-size: 13px" class="desc">
          <p class="oneline">{{info.postsContent}}</p>
        </div>
        <div class="date">{{info.postPublishTime}}</div>
      </div>
    </div>
    <slot name='icon' v-if='showIcon'>
      <i class="iconfont icon-top"></i>
    </slot>
  </div>
</template>

<script>
import groupAvatar from '@/components/groupAvatar/index.vue'

export default {
  components: {
    groupAvatar
  },
  props: {
    avatar: {
      type: String,
      default: ''
    },
    info: {
      type: Object,
      default: ()=> ({})
    },
    companyId: {
      type: [Number,String],
      default: 0
    },
    companyInfo: {
      type: Object,
      default: ()=> ({})
    },
    titleStyle: {
      type: Object,
      default: ()=> ({})
    },
    disable: {
      type: Boolean,
      default: true
    },
    showIcon: Boolean
  },
  methods: {
    goPostsInfo() {
      this.$emit('pick', this.info)
      if (!this.disable || !this.$global.ua().isTOUCH) return;
      window.sessionStorage.removeItem('groupInfo')
      window.sessionStorage.setItem('groupInfo', JSON.stringify(this.info))
      const companyInfo = this.companyInfo
      this.$router.push(`/group/detail?companyId=${this.companyId}&groupId=${this.info.groupId}&keyword=${companyInfo.companyKeyword}`)
    },
    goInfo() {
      this.$router.push(`/group/info?groupId=${this.info.groupId}`)
    }
  }
}
</script>

<style lang="less" scoped>
.cell {
  padding-top: 16px;
  position: relative;
  height: 84px;
  box-sizing: border-box;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
  width: 100%;
  overflow: hidden;
  .avatar {
    width: 44px;
    height: 44px;
    border-radius: 4px;
    overflow: hidden;
    background: #fafafa;
    margin: 0 15px;
  }
  .title {
    font-size: 15px;
    line-height: 21px;
    color: #2D2D2D;
  }
  .desc {
    font-size: 13px;
    line-height: 18px;
    color: #9B9B9B;
    margin-top: 2px;
    margin-bottom: 1px;
    position: relative;
    width: 100%;
  }
  .oneline {
    width: 100%;
    max-width: 270px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .date {
    font-size: 12px;
    line-height: 17px;
    color: #9B9B9B;
  }
  .icon-top {
    position: absolute;
    right: 10px;
    color: #999;
    font-size: 16px;
    color: #B9B9B9;
    top: 21px;
  }
}
</style>
