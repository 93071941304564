<!-- 手风琴组件 -->
<template>
  <div class="c-slideItem">
    <div class="slideItem--content" :style="{height: getHeight}">
      <div class="contain_loan" ref="contain">
        <slot></slot>
      </div>
    </div>
    <div v-if='count > 5' class="bottomTip f_flex f_a_c f_j_c theme_c" @click='showContentFn'>
      {{showPane ? '收起' : '展开'}}
      <div>
        <img v-if='showPane' style='width: 14px; margin-left: 5px' src="../../assets/arrow_up.png" alt="">
        <img v-else style='width: 14px; margin-left: 5px' src="../../assets/arrow_down.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    showPane: false,
    containH: 40
  }),
  props: {
    count: Number,
  },
  computed: {
    getHeight() {
      return this.showPane ? `${this.containH}px` : (this.count <= 5 ? 'auto' : '420px')
    }
  },
  methods: {
    showContentFn() {
      this.showPane = !this.showPane
      this.getRect()
    },
    getRect() {
      let f = this.$refs.contain
      let r = f.getBoundingClientRect()
      this.containH = r.height + 10
    }
  }
}
</script>

<style scoped lang="less">
.c-slideItem {
  background: #fff;
  position: relative;
}
.slideItem--head {
  height: 43px;
  font-size: 14px;
  padding: 14px 16px;
  box-sizing: border-box;
  position: relative;
  .slideItem__icon {
    position: absolute;
    right: 16px;
    top: 15px;
    width: 7px;
    transition: all 0.3s linear;
  }
}
.slideItem--content {
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease-out;
  box-sizing: border-box;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  .contain_loan {
    box-sizing: border-box;
    color: #A8A8A8;
    font-size: 15px;
  }
}
.bottomTip {
  margin: 12px 0;
  font-size: 13px;
  text-align: center;
}
</style>
