<template>
  <page :styleWrap='{background: "#fff"}' :footer='60' ref='wrap'>
    <div>
      <div class="f_flex f_a_c f_j_sb" style="position: sticky; top: 0; z-index: 8; background: #fff; padding: 10px 14px">
        <div class="f_g1">
          <!-- <van-search shape='round' v-model="word" placeholder="搜索"></van-search> -->
          <div class="f_g1 searchPanel f_flex f_a_c" @click="goSearchFn">
            <i class="iconfont icon-search1" style="color: #C4C4C4;"></i>
            <p style='font-size: 14px; color: #BCBCBC; margin-left: 12px'>搜索</p>
          </div>
        </div>
        <div class="buildTxt theme_c" @click='buildFn'>建群</div>
      </div>


      <div v-if='groupList.length'>
        <div style="margin: 0 15px" v-for='(item, index) in getGroupListC' :key='index'>
          <div class="buileTitle">{{item.company.companyName}}</div>
          <collapse :count="item.groupList.length" v-if='item.groupList.length'>
            <div>
              <div v-for='(ii, key) in item.groupList' :key='key' @dblclick.stop="goDetailInfo(item, ii)">
                <van-swipe-cell
                  stop-propagation
                  :class="{top: ii.topStatus === 1}">
                  <group-item :companyInfo='item.company' :companyId='item.company.companyId' :info='ii' :showIcon='ii.topStatus === 1' :key='key'></group-item>
                  <template #right>
                    <van-button
                      style="width: 100%; height: 100%"
                      @click='setTopFn(ii)'
                      square
                      type="info"
                      :text="ii.topStatus === 1 ? '取消置顶' : '置顶'" />
                  </template>
                </van-swipe-cell>
              </div>
            </div>
          </collapse>
        </div>
      </div>
      <div v-else>
        <img class="emptyImg margin_auto" src="../../assets/groupEmpty.png" alt="">
        <p class="tip">还没有加入群哦</p>
      </div>
      <div class="closeGroup" @click='$router.push("/group/close")'>关闭的群</div>
      <div class="footerPanel"></div>
    </div>
    <div slot='footer'>
      <bottom-menu></bottom-menu>
    </div>
  </page>
</template>

<script>
import bottomMenu from '@/components/bottomMenu/index.vue'
import { SwipeCell, Button } from 'vant';
import GroupItem from './groupItem.vue'
import getGroupList from '../mixins/groupList'
import groupApi from '@/api/group'
import collapse from './collapse.vue'
import {mapActions, mapGetters} from 'vuex'

export default {
  data: ()=> ({
    groupList: [],
    word: '',
    height: []
  }),
  mixins: [getGroupList],
  components: {
    'bottom-menu': bottomMenu,
    'van-swipe-cell': SwipeCell,
    'van-button': Button,
    'group-item': GroupItem,
    collapse
  },
  computed: {
    ...mapGetters(['get_router']),
    getGroupListC() {
      const c = this.groupList
      let list = []
      const reg = new RegExp(this.word)
      c.forEach(item => {
        if (item.company.companyName.match(reg)) {
          list.push(item)
        } else {
          const ll = item.groupList
          const gl = ll.filter(ii => {
            return (ii.groupName.match(reg) || ii.postsContent.match(reg))
          }) || []
          const obj = {
            company: item.company,
            groupList: gl
          }
          list.push(obj)
        }
      });

      return list
    }
  },
  methods: {
    ...mapActions(['set_groupSearchList']),
    getData() {
      this._getCompanyGroupList().then(res => {
        this.groupList = res.data.companyGroupList
        this.$global.setItem('companyGroupList', this.groupList)
        if (!this.$global.getItem('groupInfo')) {
          const firstCompany = this.groupList[0]
          const groupInfo = firstCompany && firstCompany.groupList && firstCompany.groupList.length ? firstCompany.groupList[0] : null
          if (groupInfo) {
            this.$global.setItem('groupInfo', groupInfo)
          }
        }
      })
    },
    buildFn() {
      const auth = this.$global.getItem('auth')
      const msg = '未有创建群权限'
      if (auth && auth.userAuthMap) {
        const {groupCreateAuth} = auth.userAuthMap
        if (!groupCreateAuth || groupCreateAuth.hasAuth == 2){
          this.$toast(groupCreateAuth.hasNotAuthTips || msg)
          return
        }
      }
      const length = this.groupList.length
      if (!length) {
        this.$toast(msg)
        return;
      }
      const companyInfo = this.$global.getItem('companyInfo', true)
      if (companyInfo && companyInfo.auditingStatus == 1) {
        if (length && length <= 1) {
          const companyId = this._getCompanyId_onlyOneItem(this.groupList)
          this.$router.push(`/build/chooseConact?companyId=${companyId}`)
        } else {
          this.$router.push('/build/chooseGroup')
        }
      } else {
        this.$toast('未分配角色用户不能建群')
      }
    },
    goDetailInfo(item, ii) {
      if (this.$global.ua().isTOUCH) return;
      const companyId = item.company.companyId
      const groupId = ii.groupId
      this.$router.push(`/group/detail?companyId=${companyId}&groupId=${groupId}&keyword=${item.company.companyKeyword}`)
    },
    setTopFn(item) {
      groupApi.setTop({
        groupId: item.groupId,
        topStatus: item.topStatus == 1 ? 2 : 1
      }).then(res => {
        if (res.data) {
          this.getData()
        }
      })
    },
    goSearchFn() {
      this.set_groupSearchList([])
      this.$router.push('/group/search')
    },
    scrollTopFn() {
      setTimeout(() => {
        const wrap = this.$refs.wrap.$refs.pageContent_contain
        wrap.addEventListener('scroll', (e)=> {
          this.$global.debounce(()=> {
            this.$router.history.current.meta.offsetTop = e.target.scrollTop
          })
        })
      }, 200)
    }
  },
  mounted() {
    this.getData()
    this.scrollTopFn()
    this.$route.meta.change = ()=> {
      if (this.get_router == 'backward') {
        window.sessionStorage.removeItem('groupInfo')
      }

      this.getData()
      setTimeout(() => {
        const wrap = this.$refs.wrap.$refs.pageContent_contain
        wrap.scrollTop = this.$router?.history?.current?.meta?.offsetTop || 0
      }, 20)
    }
  },
}
</script>

<style lang="less" scoped>
.buildTxt {
  font-size: 15px;
  margin-left: 11px;
}
.buileTitle {
  font-size: 15px;
  color: #434343;
  font-weight: 400;
  margin-bottom: 5px;
  margin-top: 21px;
}
.top {
  background: #F6F6F6;
}
.closeGroup {
  font-size: 13px;
  color: #9B9B9B;
  margin-top: 35px;
  text-align: center;
  text-decoration: underline;
}
.emptyImg {
  width: 150px;
  height: 120px;
  margin-top: 100px;
}
.tip {
  color: #C1CAD4;
  font-size: 14px;
  text-align: center;
  margin-top: 17px;
  margin-bottom: 150px;
}
.searchPanel {
  background: #F1F1F1;
  border-radius: 30px;
  padding: 0 18px;
  box-sizing: border-box;
  height: 34px;
}
</style>
