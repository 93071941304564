import groupApi from '@/api/group'

// 获取群列表信息
export default {
  // data: ()=> ({
  //   _groupList: []
  // }),
  methods: {
    _getCompanyGroupList(groupStatus=1) {
      return new Promise((resolve)=> {
        groupApi.groupList({
          groupStatus: groupStatus,
          companyId: JSON.parse (window.localStorage.getItem ('companyInfo')).companyId
        }).then(res => {
          resolve(res)
          // res=> {data: {companyGroupList: [{company: {name,id}, groupList: {groupName, groupId...}}]}}
        })
      })
    },
    // 仅当list 长度为1时选择  否则通过具体的item选取
    _getCompanyId_onlyOneItem(res) {
      return res[0].company.companyId
    },
    // 有id 返回某个公司的群列表  没有返回全部群
    _getGroupList(res, id) {
      if (id) {
        // console.log(id);
        const company = res.filter(item => {
          return item.company.companyId == id
        })
        return company.length ? company[0].groupList : []
      } else {
        const arr = []
        res.forEach(item => {
          arr.push(...item.groupList)
        })
        return arr
      }
    },
    _getCurrentCompany(res, id) {
      if (id) {
        const company = res.filter(item => {
          return item.company.companyId == id
        })
        return company.length ? company[0].company : null
      } else {
        res[0].company
      }
    }
  }
}
